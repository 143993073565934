:root {
	--main-bg-color: #282c34;
	--main-text-color: white;
}

input:focus { outline: none; }

.App {
	text-align: center;
	position: relative;
}

/* .search */
.search {
	background-color: var(--main-bg-color);
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: var(--main-text-color);
}

.search .logo {
	padding: 1rem;
	width: 4rem;
	height: 4rem;
	border-radius: 1.3rem;
}

.search .logo, .search .search-input, .search .search-button {
	display: inline-block;
	vertical-align: middle;
}

.hasResults > .search {
	min-height: 0;
}

/* search */
.search-input, .search-button {
	font-size: 1.5rem;
	padding: 1rem;
	margin: 0.5rem;
	background: var(--main-bg-color);
	color: var(--main-text-color);
	border: solid 2px white;
	border-radius: 10px;
}

.search-button {
	padding: 1rem 2rem;
	cursor: pointer;
	transition: box-shadow 0.1s ease-in-out;
}

.search-button:hover {
	color: var(--main-bg-color);
	background-color: var(--main-text-color);
}

.search-button:active {
	box-shadow: inset 0 0 10px var(--main-bg-color);
}

/* .powered-by */
.powered-by {
	font-size: 0.8rem;
	font-weight: bold;
	position: absolute;
	bottom: 0;
	text-align: center;
	display: block;
	width: 100vw;
}

.hasResults .powered-by {
	position: relative;
}

.hasNoResults .powered-by a {
	color: #ddd;
}

.powered-by a {
	text-decoration: none;
	color: #222;
}

.powered-by a:hover {
	color: #6bd1fa;
}

.powered-by .smalllogo {
	width: 1.5rem;
	height: 1.5rem;
	vertical-align: middle;
	padding: 0.5rem;
	border-radius: 0.7rem;
}

.favicon {
	width: 16px;
	height: 16px;
	vertical-align: middle;
	margin-right: 0.5rem;
	display: inline-block;
}

.favicon img {
	width: 16px;
	height: 16px;
	border-radius: 2px;
}

.no-favicon {
	background-color: #ddd;
	display: inline-block;
	width: 100%;
	height: 100%;
	border-radius: 2px;
}

/* .results */
.results ol {
	list-style: none;
	text-align: left;
}

.results li {
	padding: 0.5rem;
}

.hasNoResults > .results {
	display: none;
}

.results .description {
	color: #333;
	font-size: 0.9rem;
}

.results .url {
	color: #555;
	font-size: 0.8rem;
}

/* spinner */
.loader-wrapper {
	width: 40px;
	height: 28px;
	margin-left: 30px;
	position: relative;
}

.loader.show {
	display: inline-block;
}

.loader {
	position: absolute;
	top: 14px;
	display: none;
	width: 10px;
	height: 10px;
	border-radius: 50%;
	background-color: #fff;
	box-shadow: 20px 0 #fff, -20px 0 #fff;
	animation: flash 0.5s ease-out infinite alternate;
}

@keyframes flash {
	0% {
		background-color: #FFF2;
		box-shadow: 20px 0 #FFF2, -20px 0 #FFF;
	}
	50% {
		background-color: #FFF;
		box-shadow: 20px 0 #FFF2, -20px 0 #FFF2;
	}
	100% {
		background-color: #FFF2;
		box-shadow: 20px 0 #FFF, -20px 0 #FFF2;
	}
}

